import { graphql } from 'gatsby'
import React, { FC } from 'react'
import { CmsPage, CmsPageContent } from '../../cms'

export const query = graphql`
  fragment DefaultPageData on WAGTAIL_HomePage {
    ...CmsPageData
    ...CmsPageContent

    ... on WAGTAIL_HomePage {
      searchDescription
      colour
      showTitle
      image {
        ...WagtailImageFluid
      }
      showHeader
      showFooter
      type
      theme
    }
  }

  query DefaultPageQuery($id: Int!) {
    wagtail {
      ...CmsSiteData

      page(id: $id) {
        ...DefaultPageData
      }
    }
  }
`

const DefaultPage: React.FC<{ data: DefaultPageQuery }> = ({ data }) => {
  return (
    <CmsPage noTitle={!data.wagtail.page.showTitle} {...data.wagtail}>
      <CmsPageContent {...data.wagtail} />
    </CmsPage>
  )
}

export default DefaultPage
